<template>
  <div>
    <!-- <v-breadcrumbs :items="breadcrumbItems"/> -->
    <v-card :elevation="isEmbedded ? 0 : 2">
      <Header v-if="!isEmbeddedInApp" :title="$t('profile.activities.title')" >
        <v-btn text dark :to="{name:'activityEntry'}"><v-icon small class="fa fa-plus-circle" /><span class="d-none ml-1 d-sm-flex">{{$t('profile.activities.manualentry')}}</span></v-btn>    
      </Header>
      <!-- <v-card-title class="display-2">{{$t('profile.activities.title')}}</v-card-title> -->
      <v-card-text>
        {{$t('profile.activities.intro-description')}}
      </v-card-text>

      <ActivityGrid :limit="25" :profile="profile" />
      <br/>
      <v-card-actions v-if="!isEmbeddedInApp">
        <v-btn text color="primary" :to="{name: 'activityEntry'}" :exact="true">{{$t('profile.activities.manualentry')}}</v-btn>
        <v-btn text color="primary" :to="{name: 'activityUpload'}" :exact="true">{{$t('profile.activities.upload')}}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>



<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import activityService from "@/services/activityService";
import ActivityGrid from "@/components/ActivityGrid";
import profileService from "@/services/profileService";
import Header from './_Header.vue';

export default {
  name: "Activities",
  components: {
    ActivityGrid,
    Header,
  },
  props: {
  },
  data() {
    return {
      profile: null,
      breadcrumbItems: [
        { text: this.$t('profile.title'), exact: true, to: {name: 'profile'} },
        { text: this.$t('profile.activities.title'), disabled: true },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async loadData() {
      if (this.user) {
        var response = await profileService.get(/*full:*/false);
        this.profile = response.data;
      }
    },

  },
  computed: {
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

